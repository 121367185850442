const data = {
    homeLevels: 'Πόσα επίπεδα έχει το σπίτι σας;',
    threePhase: 'Έχετε τριφασικό ρεύμα;',
    singleSwitch: 'Πόσους μονούς διακόπτες έχετε;',
    doubleSwitch: 'Πόσους διπλούς διακόπτες έχετε;',
    tripleSwitch: 'Πόσους τριπλούς διακόπτες έχετε;',
    switchFrame: 'Διαθέτετε πλαίσια με πολλούς διακόπτες;',
    smartSocket: 'Πόσες πρίζες θέλετε να κάνετε έξυπνες;',
    dimmerSwitch: 'Πόσους διακόπτες dimmers έχετε;',
    shutersSwitch: 'Πόσους διακόπτες χειρισμού ρολών έχετε;',
    thermostatNumber: 'Πόσους θερμοστάτες θα χρειαστείτε;',
    consumptionRecord: 'Θέλετε να μετράτε την κατανάλωση σε συγκεκριμένα σημεία (ρευματοδότες);',
    
};
export default data;