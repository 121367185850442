import React, { useState } from 'react';
import './CSS/twoPackets.css';
import banner from './images/volton banner-01.jpg'
import { ReactComponent as ArrowSvg } from './images/arrow.svg'
import voltonSwitches from './images/buttons box-01.png'
import voltonModules from './images/buttons box-02.png'
import SwitchOrModulePacket from './SwitchOrModulePacket';

function App() {

  const [hide, setHide] = useState(false);
  const [nextComponentSwitch, setNextComponentSwitch] = useState(false);
  const [nextComponentModule, setNextComponentModule] = useState(false);

  const showNextComponentSwitch = () => {
    setHide(true);
    setNextComponentSwitch(true);
  };

  const showNextComponentModule = () => {
    setHide(true);
    setNextComponentModule(true);
  };

  return (
    <>
      {!hide && (<div className="twoPacketscontainer">
        <img src={banner} className='banner' alt="fireSpot" />
        <div className='twoPacketsMainContainer' style={{ paddingBottom: '30px' }}>
          <div className='packetsContainer'>
            <header className='twoPacketsHeader'>επιλογή πακέτου</header>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '30px', }}>
              <div className='switchPacketContainer'>
                <img className='images' src={voltonSwitches}></img>
                <div className='packetDescription'>
                  <div style={{ textAlign: 'center' }}>volton smart home system</div>
                  <div style={{ textAlign: 'center' }}>με διακόπτες</div>
                </div>
                <div className='packetsGreenButton' onClick={showNextComponentSwitch}>
                  <span>Ξεκινήστε εδώ</span>
                  <ArrowSvg style={{ width: '50%', height: '100%', paddingRight: '10%' }} />
                </div>
              </div>

              <div className='modulePacketContainer'>
                <img className='images' src={voltonModules}></img>
                <div className='packetDescription'>
                  <div style={{ textAlign: 'center' }}>volton smart home system</div>
                  <div style={{ textAlign: 'center' }}>με modules</div>
                </div>
                <div className='packetsGreenButton' onClick={showNextComponentModule}>
                  <span >Ξεκινήστε εδώ</span>
                  <ArrowSvg style={{ width: '50%', height: '100%', paddingRight: '10%' }} />
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      )}
      {(nextComponentSwitch || nextComponentModule) && <SwitchOrModulePacket nextComponentSwitch={nextComponentSwitch} nextComponentModule={nextComponentModule} />}
    </>
  );

}

export default App;
