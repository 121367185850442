import React, { useState, useEffect } from 'react';
import data from '../JSON_Files/questionText';
import flags from '../JSON_Files/questionsRadioButtonFlag';
import hiddenQuestions from '../JSON_Files/hiddenQuestions';
import selectedChoicesText from '../JSON_Files/selectedChoicesText';
import selectedChoicePrice from '../prices/selectedChoicePrice';
import standardPacket from '../JSON_Files/standardPacket';

function Questions({ onChoicesChange, setExtras, extras, setHomeSize, buttonCostClicked }) {

  const [choicesArray, setChoicesArray] = useState([]);
  const [flag, setFlag] = useState(false);
  const [homeSizeFlag, setHomeSizeFlag] = useState(false)

  useEffect(() => {
    onChoicesChange(choicesArray);
  }, [choicesArray, onChoicesChange]);

  const currentValue = (key) => {
    const inputElement = document.getElementById(key);
    const hiddenElement = document.getElementById("hidden_" + key);

    if (inputElement && inputElement.type === "number") {
      let newValue = inputElement?.value;
      if (newValue > 0) {
        setHomeSizeFlag(true);
      }
      else {
        setHomeSizeFlag(false);
      }
      updateChoicesArray(key, parseInt(newValue));
    }
    if (hiddenElement && hiddenElement.type === "number") {
      let newValue = hiddenElement?.value;
      updateChoicesArray("hidden_" + key, parseInt(newValue));
    }
  }

  const increaseValue = (key) => {
    const inputElement = document.getElementById(key);

    if (inputElement) {
      const newValue = parseInt(inputElement.value) + 1;
      inputElement.value = newValue;
      updateChoicesArray(key, newValue);
    }
  };

  const decreaseValue = (key) => {
    const inputElement = document.getElementById(key);
    if (inputElement && parseInt(inputElement.value) > 0) {
      const newValue = parseInt(inputElement.value) - 1;
      inputElement.value = newValue;
      updateChoicesArray(key, newValue);
    }
  };

  Object.keys(selectedChoicesText).forEach(key => {
    if (extras[key] !== undefined) {
      if (selectedChoicePrice[key].noVat === null || selectedChoicePrice[key].Vat === null) {
        setHomeSize(extras[key]);
        setExtras(prevExtras => {
          const newExtras = { ...prevExtras };
          delete newExtras[key];
          return newExtras;
        });
      }
    }
  });

  const updateChoicesArray = (key, value) => {
    const updatedChoices = choicesArray.filter(choice => choice.key !== key);
    updatedChoices.push({ key, value });
    setChoicesArray(updatedChoices);

    if (standardPacket[key]) {
      if (value > standardPacket[key]) {
        value = value - standardPacket[key]
        setExtras((prevExtras => {
          prevExtras[key] = value;
          return { ...prevExtras }
        }))
      } else {
        setExtras((prevExtras => {
          delete prevExtras[key]
          return { ...prevExtras }
        }))
      }
    } else {
      setExtras((prevExtras => {
        prevExtras[key] = value;
        return { ...prevExtras }
      }))
    }
  };

  let flagsInData = Object.fromEntries(Object.entries(data).filter(([k]) => flags[k]));
  let flagsInDataKeys = Object.keys(flagsInData);
  let hiddenQuestion = Object.keys(hiddenQuestions);
  let selectedChoice = Object.keys(selectedChoicesText);
  let newElement = [];

  const handleCheckboxClick = (event) => {
    let id = event.target.id;
    if (id && hiddenQuestion.includes(id)) {
      let element = document.getElementById(id + "_hidden");
      if (element.style.display === "none")
        element.style.display = "flex";
      else {
        element.style.display = "none";
      }
    }
    if (id && selectedChoice.includes(id) && !flag) {
      newElement = [id, 1];
      setFlag(true);
      updateChoicesArray(id, 1);
    }
    else {
      if (id && hiddenQuestion.includes(id)) {
        updateChoicesArray("hidden_" + id, 0);
        document.getElementById("hidden_" + id).value = 0;
      }
      else {
        updateChoicesArray(id, 0);
      }
      setFlag(false);

    }
  }

  const mappedQuestions = Object.entries(data).map(([key, value]) => (
    (
      <div className='questionsContainer' key={key}>
        <div style={{ display: 'flex' }}>
          <div className='questionText'>{value}</div>
          {flagsInDataKeys.includes(key) ? (
            <div className={buttonCostClicked ? 'checkboxContainerAfter' : 'checkboxContainerBefore'}>
              <label>
                <input
                  type="checkbox"
                  name="checkbox-group"
                  id={key}
                  onClick={handleCheckboxClick}
                />
                <span className="custom-checkbox"></span>
              </label>
            </div>
          ) : (
            <div className="counter">
              <button onClick={() => decreaseValue(key)}>-</button>
              {key === "homeLevels" ? (<input
                id={key}
                defaultValue={1}
                type="number"
                onChange={() => { currentValue(key) }}
              />
              ) : (
                <input
                  id={key}
                  defaultValue={0}
                  type="number"
                  onChange={() => { currentValue(key) }}
                />
              )}

              <button onClick={() => increaseValue(key)}>+</button>
            </div>
          )}
        </div>
        {hiddenQuestions?.[key] &&
          <div className='hiddenContainer' id={`${key}_hidden`} key={key} style={{ display: 'none' }}>
            <div className='questionText'>{hiddenQuestions?.[key]} </div>
            <div className="counter">
              <button id="decrement" onClick={() => decreaseValue("hidden_" + key)}>-</button>
              <input
                id={"hidden_" + key}
                defaultValue={0}
                type='number'
                onChange={() => { currentValue(key) }}
              />
              <button id="increment" onClick={() => increaseValue("hidden_" + key)}>+</button>
            </div>
          </div>}
      </div>
    )
  ));

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div style={{ backgroundColor: '#F1F0F0', display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '5px', paddingTop: '1%' }}>
        <div style={{ backgroundColor: '#F1F0F0', paddingLeft: '5%', width: '100%', paddingTop: '2.5%', paddingBottom: '1%', fontFamily: 'Manrope', color: 'black', fontSize: '18px' }}>Πόσα τετραγωνικά μέτρα είναι το σπίτι σας;</div>
        <div className="counterHomeSize" style={{ paddingLeft: '8%', width: 'fit-content' }}>
          <input
            id="homeSize"
            defaultValue={0}
            type='number'
            onChange={() => { currentValue("homeSize") }}
            style={{ width: '40%', height: '60%', fontFamily: 'Manrope', fontSize: '18px', fontWeight: '500' }}
            className={homeSizeFlag ? 'nonEmptyHomeSize' : 'emptyHomeSize'}
          />
        </div>
      </div>
      <div className='questionContainer'>
        {mappedQuestions}
      </div>
    </div>

  );
}

export default Questions;
