import React, { useEffect, useState } from 'react';
import { PDFDownloadLink, Font } from '@react-pdf/renderer';
import './CSS/App.css';
import Questions from './functions/Questions';
import selectedChoiceText from './JSON_Files/selectedChoicesText';
import selectedChoiceModuleText from './JSON_Files/selectedChoicesModulesText';
import selectedChoicePrice from './prices/selectedChoicePrice';
import selectedChoiceModulePrice from './prices/selectedChoiceModulePrice';
import extraDevices from './JSON_Files/extraDevices';
import standardPacket from './JSON_Files/standardPacket';
import MyDocument from './functions/PdfGenerator';
import banner from './images/volton banner-01.jpg'
import { ReactComponent as ArrowSvg } from './images/arrow.svg'
import { ReactComponent as ArrowBlackSvg } from './images/arrow_black.svg'


function SwitchOrModulePacket({ nextComponentSwitch, nextComponentModule }) {

  const [choicesText, setChoicesText] = useState({});
  const [choicesPrice, setChoicesPrice] = useState({});

  const [choicesArray, setChoicesArray] = useState([]);
  const [extras, setExtras] = useState({});

  const [basicPacketFlag, setBasicPacketFlag] = useState(false);
  const [generatePDF, setGeneratePDF] = useState(false);
  const [extrasFlag, setExtrasFlag] = useState(false);
  const [buttonCostClicked, setButtonCostClicked] = useState(false);

  const [homeSize, setHomeSize] = useState(0);
  const [totalFloors, setTotalFloors] = useState(0);
  const [vat, setVat] = useState(0);
  const [pdfFinalRouter, setPdfFinalRouter] = useState(0);
  const [singleModule, setSingleModule] = useState(0);
  const [doubleModule, setDoubleModule] = useState(0);

  const [finalPriceNoVat, setFinalPriceNoVat] = useState("");
  const [finalPriceVat, setFinalPriceVat] = useState("");


  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0');
  let yyyy = today.getFullYear();
  let date = dd + '/' + mm + '/' + yyyy;

  let totalPriceNoVat = 0;
  let totalPriceVat = 0;
  let updatedNoVat = 0;
  let updatedVat = 0;
  let extraDouble = 0;
  let extraSingle = 0;
  let hiddenDevicesDouble = 0;
  let hiddenDevicesSingle = 0;
  let finalRouters = 0;
  let devicesCounter = 9;
  let priceNoVat = 0;
  let priceVat = 0;
  let formattedPriceNoVat = "";
  let formattedPriceVat = "";


  useEffect(() => {
    if (nextComponentSwitch) {
      setChoicesText(selectedChoiceText);
      setChoicesPrice(selectedChoicePrice);
    } else {
      setChoicesText(selectedChoiceModuleText);
      setChoicesPrice(selectedChoiceModulePrice);
    }
  }, [nextComponentSwitch])


  useEffect(() => {
    checkForExtraFloor();
    devicesCounter = 9;
  }, [choicesArray])


  useEffect(() => {
    let extraRouter = 0;
    let switchFrameDevices = 0;
    let remainingDevices = 0;

    if (extras.hidden_switchFrame) {
      if (extras.hidden_switchFrame % 2 === 0) {

        hiddenDevicesDouble = parseInt(extras.hidden_switchFrame / 2);
        hiddenDevicesSingle = 0;
        setSingleModule(hiddenDevicesSingle);
        setDoubleModule(hiddenDevicesDouble);
      }
      else {
        hiddenDevicesDouble = parseInt(extras.hidden_switchFrame / 2);
        hiddenDevicesSingle = extras.hidden_switchFrame % 2;
        setSingleModule(hiddenDevicesSingle);
        setDoubleModule(hiddenDevicesDouble);
      }

    }

    Object.keys(choicesText)?.forEach(key => {
      if (extras[key] !== undefined && key !== "homeSize" && key !== "homeLevels") {
        if (key === "hidden_switchFrame") {
          switchFrameDevices = hiddenDevicesDouble + hiddenDevicesSingle;
        }
        else {
          remainingDevices += extras[key];
        }
      }

    })

    devicesCounter = devicesCounter + switchFrameDevices + remainingDevices;
    let extraDevices = devicesCounter - 25;
    if (extraDevices <= 0) {
      extraRouter = 0;
    } else {
      extraRouter = Math.ceil(extraDevices / 15);
    }


    if (extraRouter < extras.homeLevels) {
      extraRouter = extras.homeLevels;
    }
    // console.log("extraRouterTest", extraRouter);

    // if (!extras.homeLevels) {
    //   extras.homeLevels = 1;
    // }

    // let devicesPerLevel = extraDevices / extras.homeLevels;
    // console.log("devicesPerLevel:",devicesPerLevel);
    // if (devicesPerLevel <= 25) {
    //   extraRouter = 1;
    // }
    // else {
    //   let temp = devicesPerLevel - 25;
    //   console.log("temp__ devicesPerLevel", devicesPerLevel);
    //   extraRouter = Math.ceil(temp / 15) + 1;
    //   console.log("temp__ extraRouter", extraRouter);

    // }
    // let routers = extraRouter * extras.homeLevels;
    // console.log("temp__routers:", routers);

    // if (devicesCounter - 25 > 0) {
    //   if (extras && extras.homeLevels) {
    //     let devices = (devicesCounter - 25) / extras.homeLevels;
    //     console.log("Devices_:", parseInt(devices));
    //     // let router = / (devicesCounter/15)
    //   }
    // }
    // else {
    //   if (extras && extras.homeLevels) {
    //     extraRouter = 1;
    //   }
    //   else {
    //     extraRouter = 0;
    //   }

    // }

    // console.log("extraRouter__:", extraRouter);

    finalRouters = extraRouter;

    if (finalRouters === extras.homeLevels) {
      finalRouters = 0;
    }

    if (finalRouters > extras.homeLevels) {
      setExtras((prevExtras => {
        delete prevExtras["homeLevels"]
        return { ...prevExtras }
      }))
    }

    setPdfFinalRouter(finalRouters - 1);
  }, [extras])

  const hidden_switchFramePrice = () => {


    if (Object.keys(extras).length > 0) {
      if (extras.hidden_switchFrame) {
        if (extras.hidden_switchFrame % 2 === 0) {

          extraDouble = parseInt(extras.hidden_switchFrame / 2);
          extraSingle = 0;

        }
        else {
          extraDouble = parseInt(extras.hidden_switchFrame / 2);
          extraSingle = extras.hidden_switchFrame % 2;

        }

        updatedNoVat = extraSingle * choicesPrice["singleModule"].noVat + extraDouble * choicesPrice["doubleModule"].noVat;
        updatedVat = extraSingle * choicesPrice["singleModule"].vat + extraDouble * choicesPrice["doubleModule"].vat;

        choicesPrice["hidden_switchFrame"].noVat = updatedNoVat;
        choicesPrice["hidden_switchFrame"].vat = updatedVat;


      }
    }
  }



  const priceCalculation = () => {


    Object.entries(extras).forEach(element => {
      let key = element[0];
      let value = element[1];
      if (key === "hidden_switchFrame" && key !== undefined) {
        priceNoVat = priceNoVat + choicesPrice[key]?.noVat;
        priceVat = priceNoVat * 1.24;
      }
      else {
        totalPriceNoVat = choicesPrice[key]?.noVat * value;
        totalPriceVat = (choicesPrice[key]?.noVat * 1.24) * value;
        priceNoVat = priceNoVat + totalPriceNoVat;
        priceVat = priceVat + totalPriceVat;
      }
    })
  }

  // const routerRangeTable = [
  //   { min: 9, max: 25, value: 0 },
  //   { min: 26, max: 40, value: 1 },
  //   { min: 41, max: 55, value: 2 },
  //   { min: 56, max: 70, value: 3 },
  //   { min: 71, max: 85, value: 4 },
  //   { min: 86, max: 100, value: 5 },
  //   { min: 101, max: 115, value: 6 }
  // ]

  // const addExtraRouter = () => {

  //   let extraRouter = 0;
  //   let switchFrameDevices = 0;
  //   let remainingDevices = 0;


  //   console.log("_extras:", extras);
  //   Object.keys(selectedChoiceText).forEach(key => {
  //     if (extras[key] !== undefined && key !== "homeSize" && key !== "homeLevels") {
  //       if (key === "hidden_switchFrame") {
  //         console.log("extra__Double:", hiddenDevicesDouble);
  //         console.log("extra__Single:", hiddenDevicesSingle);
  //         switchFrameDevices = hiddenDevicesDouble + hiddenDevicesSingle;
  //         console.log("____switchFrameDevices:", switchFrameDevices);

  //       }
  //       else {
  //         remainingDevices += extras[key];
  //         console.log("____remainingDevices:", remainingDevices);
  //       }
  //     }

  //   })

  //   devicesCounter = devicesCounter + switchFrameDevices + remainingDevices;
  //   console.log("devicesCounter:", devicesCounter);


  //   if (devicesCounter < 26) {
  //     extraRouter = 0;
  //   }
  //   else {
  //     // extraRouter = devicesCounter / 15;
  //     console.log("ROUTER_:",parseInt((devicesCounter - 26) / 15 + 1));
  //     // extraRouter = 
  //   }




  //   // for (const range of routerRangeTable) {
  //   //   if (devicesCounter >= range.min && devicesCounter <= range.max) {
  //   //     if (devicesCounter < 25 && extras["homeLevels"] === 1) {
  //   //       break;
  //   //     }
  //   //     extraRouter += range.value;
  //   //     console.log("extraRouter___:", extraRouter);
  //   //     break;
  //   //   }
  //   // }
  //   // console.log("HOME:", document.getElementById("homeLevels").value);
  //   // if (extras) {
  //   // if (parseInt(document.getElementById("homeLevels").value) === extraRouter) {
  //   //   console.log("router the same:", document.getElementById("homeLevels").value);
  //   //   return;
  //   // }
  //   // else {


  //   setFinalRouters(parseInt((devicesCounter - 24) / 15 + 1));


  //   console.log("finalRouters:", finalRouters);

  //   // }


  // }




  const handleQuestionData = (data) => {
    if (data.length === 0)
      return;
    setChoicesArray(data);
  };


  const checkForExtraFloor = () => {
    let basicHomeLevel = standardPacket["homeLevels"];
    if (Object.keys(extras).length > 0) {
      if (extras["homeLevels"]) {
        setTotalFloors(basicHomeLevel + extras["homeLevels"]);
      }
    }
    else {
      setTotalFloors(basicHomeLevel);
    }
  }



  const deleteIfZero = () => {
    Object.keys(choicesText)?.forEach(key => {
      if (extras[key] !== undefined) {
        if (extras[key] === 0) {
          setExtras((prevExtras => {
            delete prevExtras[key]
            return { ...prevExtras }
          }))
        }
      }
    })
  }

  const calculateCost = () => {
    if (homeSize === 0) {
      window.alert("Παρακαλώ εισάγετε τα τετραγωνικά μέτρα του σπιτιού σας!");
      return;
    }
    else if (homeSize < 0) {
      window.alert("Παρακαλώ εισάγετε μη αρνητικό αριθμό!");
      return;
    }
    else if (!Number.isInteger(homeSize)) {
      window.alert("Παρακαλώ εισάγετε ακέραιο αριθμό!");
      return;
    }

    if (document.getElementById("homeLevels").value === "0") {
      window.alert("Παρακαλώ επιλέξτε τουλάχιστον ένα επίπεδο!");
      setBasicPacketFlag(false);
      return;
    }

    for (let key of Object.keys(extraDevices)) {

      let userInput = parseInt(document.getElementById(key).value);

      if (userInput !== "on" && key !== "threePhase") {
        if (!Number.isInteger(userInput)) {
          window.alert("Παρακαλώ εισάγετε ακέραιο αριθμό!");
          return;
        }
        else if (Number.isInteger(userInput) && userInput < 0) {
          window.alert("Παρακαλώ εισάγετε ακέραιο αριθμό μεγαλύτερο του μηδενός!");
          return;
        }
      }
    }

    setButtonCostClicked(true);

    if (nextComponentSwitch) {
      priceNoVat = 967.74;
      priceVat = priceNoVat * 1.24;
    }
    else {
      priceNoVat = 725.0;
      priceVat = priceNoVat * 1.24;
    }

    const chosenDevicesElement = document.getElementById("chosenDevices");
    const extraDevicesElement = document.getElementById("extraDevices");
    const totalPriceElement = document.getElementById("totalPrice");


    chosenDevicesElement.innerHTML = "";
    extraDevicesElement.innerHTML = "";
    totalPriceElement.innerHTML = "";



    deleteIfZero();

    setGeneratePDF(true);
    setBasicPacketFlag(true);


    Object.keys(choicesText)?.forEach((key, index) => {
      if (index > 1 && standardPacket[key]) {
        if (standardPacket[key] > 1) {
          chosenDevicesElement.innerHTML += `${standardPacket[key]} ${choicesText[key].multiple} <br/>`;
        }
        else {
          chosenDevicesElement.innerHTML += `${standardPacket[key]} ${choicesText[key].single} <br/>`;
        }
      }
    });


    hidden_switchFramePrice();


    if (Object.keys(extras).length > 0) {
      document.getElementById("extraDevicesContainer").style.display = 'block';
      Object.keys(choicesText)?.forEach((key, index) => {
        if (extras[key]) {
          setExtrasFlag(true);
          if (extras[key] > 1) {
            extraDevicesElement.innerHTML += `${extras[key]} ${choicesText[key].multiple} <br/>`;
          } else {
            extraDevicesElement.innerHTML += `${extras[key]} ${choicesText[key].single} <br/>`;
          }
        }
      });
    } else {
      document.getElementById("extraDevicesContainer").style.display = 'none';
    }
    priceCalculation();

    formattedPriceNoVat = priceNoVat.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    formattedPriceVat = priceVat.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const numericPriceNoVat = Number(formattedPriceNoVat.replace(/\./g, '').replace(',', '.'));
    const numericPriceVat = Number(formattedPriceVat.replace(/\./g, '').replace(',', '.'));


    setFinalPriceNoVat(numericPriceNoVat);
    setFinalPriceVat(numericPriceVat);
    setVat(priceVat - priceNoVat);

    totalPriceElement.innerHTML = `${formattedPriceVat}€`;
  };




  return (
    (nextComponentSwitch || nextComponentModule) && (<div className="container">
      <img src={banner} className='banner' alt="fireSpot" />
      <div className='mainContainer'>
        <div className='innerContainer'>
          <header className='header'>κοστολόγηση</header>
          <div className='topContainer'>
            <div className='leftColumn'>
              <Questions onChoicesChange={handleQuestionData} setExtras={setExtras} extras={extras} setHomeSize={setHomeSize} buttonCostClicked={buttonCostClicked} nextComponentSwitch={nextComponentSwitch} nextComponentModule={nextComponentModule} />
            </div>
            <div className='rightColumn'>
              <div className={buttonCostClicked ? 'borderAfter' : 'borderBefore'}>
                <div className='resultsHeader'>καταγραφή αναγκών</div>
                <div className={basicPacketFlag ? 'resultsDescription' : 'resultsDescriptionHidden'}>
                  Έχετε επιλέξει το βασικό πακέτο το οποίο περιέχει τα εξής:
                </div>
                <div id='chosenDevices'></div>
                <div id='extraDevicesContainer' style={{ display: 'none' }}>
                  <div className={extrasFlag ? 'extrasResultsDescription' : 'resultsDescriptionHidden'}>Επιπλέον έχετε επιλέξει:</div>
                  <div id='extraDevices'></div>
                </div>

              </div>
            </div>
          </div>
          <div className={buttonCostClicked ? 'bottomContainerAfter' : 'bottomContainerBefore'}>
            <div className={buttonCostClicked ? 'bottomContainerAfter' : 'calculationBtnContainerBefore'} onClick={calculateCost}><span className={buttonCostClicked ? 'costCalculationButtonAfter' : 'costCalculationButtonBefore'}>ΥΠΟΛΟΓΙΣΜΟΣ ΚΟΣΤΟΥΣ</span></div>
            <div className={buttonCostClicked ? 'bottomRightAfter' : 'bottomRightBefore'}>
              <div className='dealInfo'>
                <div>
                  Για να γίνει το σπίτι σας έξυπνο, θα χρειαστούν:
                </div>
                <div id='totalPrice' className='dealPrice'></div>

              </div>
              <div className='greenButton' style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}>
                {generatePDF ? <PDFDownloadLink document={<MyDocument extras={extras} finalChoicesText={choicesText} finalChoicesPrice={choicesPrice} finalPriceNoVat={Number(finalPriceNoVat)} finalPriceVat={Number(finalPriceVat)} date={date} homeSize={homeSize} totalFloors={totalFloors} vat={vat} finalRouters={pdfFinalRouter} nextComponentSwitch={nextComponentSwitch} nextComponentModule={nextComponentModule} hiddenDevicesDouble={doubleModule} hiddenDevicesSingle={singleModule} />} fileName="κοστολόγηση.pdf" className='costEstimate'>
                  ΘΕΛΩ ΑΝΑΛΥΤΙΚΗ ΠΡΟΣΦΟΡΑ
                </PDFDownloadLink> : <div className='costEstimate' style={{ cursor: 'pointer' }} onClick={() => { window.alert("Για δημιουργία αναλυτικής προσφοράς, παρακαλώ επιλέξτε τουλάχιστον ένα επίπεδο και υπολογίστε το κόστος!") }}>ΘΕΛΩ ΑΝΑΛΥΤΙΚΗ ΠΡΟΣΦΟΡΑ</div>}
                <ArrowSvg style={{ paddingLeft: '10px', width: '20%', height: '100%' }} />
              </div>
              <div className='greenButton greenButtonExtra' style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
                <span>ΕΠΙΘΥΜΩ ΝΑ ΓΙΝΕΙ ΑΥΤΟΨΙΑ</span>
                <ArrowSvg style={{ width: '20%', height: '100%' }} />
              </div>
              <div className='linksContainer' >
                <span className='eshopLink'>
                  <span>ΜΕΤΑΦΕΡΘΕΙΤΕ ΣΤΟ ESHOP</span>
                  <ArrowBlackSvg style={{ width: '12%', height: '100%', marginRight: '20px' }} />
                </span>
                <span className="partnersLink">
                  <span>ΧΑΡΤΗΣ ΠΙΣΤΟΠΟΙΗΜΕΝΩΝ ΕΓΚΑΤΑΣΤΑΤΩN</span>
                  <ArrowBlackSvg style={{ width: '12%', height: '100%', marginRight: '20px' }} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)
  );
}
export default SwitchOrModulePacket;