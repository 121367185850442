const choicesPrice = {
    homeSize: { noVat: null, vat: null },
    homeLevels: { noVat: 87.98, vat: 109.09 },
    tablet: { noVat: 0.00, vat: 0.00 },
    hub: { noVat: 0.00, vat: 0.00 },
    threePhase: { noVat: 70.16, vat: 87.00 },
    singleSwitch: { noVat: 37.83, vat: 46.91 },
    doubleSwitch: { noVat: 43.99, vat: 54.55 },
    tripleSwitch: { noVat: 52.78, vat: 65.45 },
    howMany: { noVat: 0, vat: 0 },
    smartSocket: { noVat: 43.99, vat: 54.55 },
    dimmerSwitch: { noVat: 52.78, vat: 65.45 },
    shutersSwitch: { noVat: 52.78, vat: 65.45 },
    thermostatNumber: { noVat: 101.61, vat: 126.00 },
    consumptionRecordSpots: { noVat: 40.32, vat: 50.00 },
    hidden_switchFrame: { noVat: 0.00, vat: 0.00 },
    hidden_consumptionRecord: { noVat: 40.32, vat: 50.00 },
    energyMetering: { noVat: 0.00, vat: 0.00 },
    singleModule: { noVat: 29.03, vat: 36.00 },
    doubleModule: { noVat: 33.87, vat: 42.00 },
};
export default choicesPrice;