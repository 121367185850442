const choicesModules = {
    homeSize: {single:'',multiple:'',singlePdf:''},
    homeLevels: {single:'Router',multiple:'Routers',singlePdf:'Router'},
    tablet :{single:'Οθόνη έξυπνου σπιτιού',multiple:'Οθόνες έξυνου σπιτιού',singlePdf:'Οθόνη έξυπνου σπιτιού'},
    hub :{single:'Hub',multiple:'Hubs',singlePdf:'Hub'},
    threePhase:{single:'Καταγραφέας Ενέργειας',multiple:'Καταγραφέας Ενέργειας',singlePdf:'Καταγραφέας Ενέργειας'},
    singleSwitch: {single:'Μονό module',multiple:'Μονά modules',singlePdf:'Μονό module'},
    doubleSwitch: {single:'Διπλό module',multiple:'Διπλά modules',singlePdf:'Διπλό module'},
    tripleSwitch: {single:'Τριπλό module',multiple:'Τριπλά modules',singlePdf:'Τριπλό module'},
    hidden_switchFrame: {single:'Πλαίσιο',multiple:'Πλαίσια',singlePdf:'Πλαίσιο'},
    smartSocket: {single:'Module για πρίζα',multiple:'Modules για πρίζες'},
    dimmerSwitch: {single:'Module για dimmer',multiple:'Modules για dimmers',singlePdf:'Module για dimmer'},
    shutersSwitch: {single:'Module χειρισμού ρολών',multiple:'Module χειρισμού ρολών',singlePdf:'Module χειρισμού ρολών'},
    thermostatNumber: {single:'Θερμοστάτη',multiple:'Θερμοστάτες',singlePdf:'Θερμοστάτης'},
    hidden_consumptionRecord: {single:'Σημείο',multiple:'Σημεία',singlePdf:'Σημείο'},
    energyMetering: {single:'Energy metering module',multiple:'Energy metering module',singlePdf:'Energy metering module'},
};
export default choicesModules;