const standardPacket = {
    homeLevels: 1,
    tablet: 1,
    hub: 1,
    // threePhase:1,
    singleSwitch: 4,
    doubleSwitch: 2,
    // tripleSwitch:0,
    // hidden_switchFrame:0,
    // switchFrame:0,
    smartSocket: 2,
    // dimmerSwitch:0,
    // shutersSwitch:0,
    // thermostatNumber: 1,
    // hidden_consumptionRecord:0,
    energyMetering:1
};

export default standardPacket;


